export const calenderMixin = {
  data() {
    return {
      dateFomartion: 'DD/MM/YYYY',
      nowDate: new Date(),

      numOptions: [
        { text: '1', value: 1 },
        { text: '2', value: 2 },
        { text: '3', value: 3 },
        { text: '4', value: 4 },
        { text: '5', value: 5 },
        { text: '6', value: 6 },
        { text: '7', value: 7 },
        { text: '8', value: 8 },
        { text: '9', value: 9 },
        { text: '10', value: 10 },
      ],

      timeOptions: [
        { text: 'Ngày', value: 0 },
        { text: 'Tuần', value: 1 },
        { text: 'Tháng', value: 2 },
      ],

      weekdayOptions: [
        { text: 'CN', value: 0 },
        { text: 'T2', value: 1 },
        { text: 'T3', value: 2 },
        { text: 'T4', value: 3 },
        { text: 'T5', value: 4 },
        { text: 'T6', value: 5 },
        { text: 'T7', value: 6 },
      ],

      endOptions: [
        { name: 'Không bao giờ', value: 0 },
        { name: 'Vào ngày', value: 1 },
      ],
    };
  },

  methods: {
    getDayOfDate(date) {
      if (date) return date.split('/')[0];
      return '';
    },

    formatDate(date) {
      if (!date) return this.$moment();
      return this.$moment(date, 'DD/MM/YYYY HH:mm:ss');
    },
  },
};
